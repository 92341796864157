import React, {useState, useEffect} from "react";
import { isEmpty } from 'lodash';
import { Link } from "@StarberryUtils"
import {Form, Container, Row, Col} from 'react-bootstrap';
import StepZero from './StepZero/StepZero'
import StepOne from './StepOne/StepOne'
import SEO from "@Components/seo";
import StepTwo from './StepTwo/StepTwo'
import StepThree from './StepThree/StepThree'
import CalendarComponents from './CalendarBlock/CalendarBlock'
import BookViewImg from '../../images/valuation-img.jpg'
import './PropertyValuation.scss'
import ReCaptchaBox from '@Components/forms/elements/recaptcha'
import { postFormData } from "../forms/api/Api"
import axios from "axios"
import * as qs from "query-string"
import { FORSALE_Manchester_PAGE_URL, CONTACT_PAGE_URL } from "@Components/common/site/constants";
import moment from "moment"
import { TEAM_PAGE_URL } from "@Components/common/site/constants";
import $ from 'jquery'
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getUser } from "@myaccount/website-service";

const BookValuations = ( props ) => {
  const data1 = useStaticQuery(graphql`
  query {
    glstrapi {
      contact(publicationState: LIVE) {
        Address
        Email
        Embed_Video_URL
        Latitude
        Longitude
        Opening_Hours
        Phone
        CTA {
          CTA_1_Label
          CTA_1_URL {
            Alias
          }
        }
        Image {
          alternativeText
          url
        }
        speak_with {
          Designation
          Name
          Phone
          Image {
            alternativeText
            url
          }
          URL
        }
        CTA {
          CTA_1_Label
          CTA_1_URL {
            Alias
          }
        }
      }

    }
  }
`);
  const userData = getUser();
  const [data, updateData] = useState({})
  const recaptchaRef = React.createRef();
  const [formvalues, setFormvalues] = useState("");
  const [token, setToken] = useState("");
  const handleBack = () => window.history.back();
  useEffect(() => {
    window.scrollTo(0, 0);
    updateData(JSON.parse(sessionStorage.getItem('data')))
}, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Prefill user data if user logined in
    if (!isEmpty(userData)) {
        if (!sessionStorage.getItem('name'))
          setName(sessionStorage.setItem('name', userData?.name || ''));
        if (!sessionStorage.getItem('lastName'))
          setLastName(sessionStorage.setItem('lastName', userData?.surname || ''));
        if (!sessionStorage.getItem('email'))
          setEmail(sessionStorage.setItem('email', userData?.email || ''));
        if (!sessionStorage.getItem('telephone'))
          setPhone(sessionStorage.setItem('telephone', userData?.mobile || ''));
    }

    // setStep(sessionStorage.getItem('step') != null ? parseInt(sessionStorage.getItem('step')) : 0)
    setName(sessionStorage.getItem('name') != null ? sessionStorage.getItem('name') : userData?.name || '')
    setLastName(sessionStorage.getItem('lastName') != null ? sessionStorage.getItem('lastName') : userData?.surname || '')
    setEmail(sessionStorage.getItem('email') != null ? sessionStorage.getItem('email') : userData?.email || '')
    setPhone(sessionStorage.getItem('telephone') != null ? sessionStorage.getItem('telephone') : userData?.mobile || '')
    setAddress(sessionStorage.getItem('address') != null ? sessionStorage.getItem('address') : '')
    setAddress(sessionStorage.getItem('post_code') != null ? sessionStorage.getItem('post_code') : '')
    setPropertySell(sessionStorage.getItem('sellorlet') != null ? sessionStorage.getItem('sellorlet') : false)
    setDate(null)
    setValuationType(sessionStorage.getItem('type_of_valuation') != null ? sessionStorage.getItem('type_of_valuation') : '')

    setAddressBuildingName(sessionStorage.getItem('addressBuildingName') != null ? sessionStorage.getItem('addressBuildingName') : '')
    setAddressBuildingNumber(sessionStorage.getItem('addressBuildingNumber') != null ? sessionStorage.getItem('addressBuildingNumber') : '')
    setAddressPostalCode(sessionStorage.getItem('addressPostalCode') != null ? sessionStorage.getItem('addressPostalCode') : '')
    setAddressSubBuilding(sessionStorage.getItem('addressSubBuilding') != null ? sessionStorage.getItem('addressSubBuilding') : '')
    setAddressStreet(sessionStorage.getItem('addressStreet') != null ? sessionStorage.getItem('addressStreet') : '')


  }, []);
  const [step, setStep] = useState(1)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [valemailError, setValEmailError] = useState(false)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [valphoneError, setValPhoneError] = useState(false)
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState('')
  const [propertySell, setPropertySell] = useState(false)
  const [date, setDate] = useState(null)
  const [submitted, stepSubmitted] = useState(false)
  const [valuationType, setValuationType] = useState('')
  const [addressBuildingName, setAddressBuildingName] = useState('')
  const [addressBuildingNumber, setAddressBuildingNumber] = useState('')
  const [addressPostalCode, setAddressPostalCode] = useState('')
  const [addressSubBuilding, setAddressSubBuilding] = useState('')
  const [addressStreet, setAddressStreet] = useState('')
  const [valpalsell, setValpalSell] = useState('')
  const [valpallet, setValpalLet] = useState('')
  const onChange = (e) => {
    // debugger
    sessionStorage.setItem(e.target.name, e.target.value)
    if (e.target.name === 'name') {
      // debugger
      setName(e.target.value)
      if(e.target.value !== ""){
        setNameError(false)
      }
      else {
        setNameError(true)
      }
    }
    if (e.target.name === 'lastName') {
      // debugger
      setLastName(e.target.value)
      if(e.target.value !== ""){
        setLastNameError(false)
      }
      else {
        setLastNameError(true)
      }
    }
    if (e.target.name === 'email') {
      // debugger
      setEmail(e.target.value)
      if(e.target.value === ""){
        setEmailError(true)
      }
      else if(e.target.value.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null){
        setValEmailError(true)
        setEmailError(false)
      }
      else {
        setEmailError(false)
        setValEmailError(false)
      }
    }
    if (e.target.name === 'telephone') {
      // debugger
      setPhone(e.target.value)
      if(e.target.value === ""){
        setPhoneError(true)
      }
        else if (e.target.value.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null){
          setValPhoneError(true)
          setPhoneError(false)
        }
      else{
        setPhoneError(false)
        setValPhoneError(false)
      }
    }
    if (e.target.name === 'sellorlet') {
      // debugger
      setPropertySell(e.target.value)
    }
    // if(e.target.name === 'address'){
    //   setAddress(e.target.value)
    //   if(e.target.value === ""){
    //     setAddressError(true)
    //   }
    //   else {
    //     setAddressError(false)
    //   }
      
    // }

  }
  const next = event => {
    
    if(step === 0){
      // debugger
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      setStep(step+1)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'valuation-step-one',
        'formId': 'valuation-step-one',
        'formName': 'Valuation Step One',
        'formLabel': 'Valuation Step One'
      });
    }
    if(step === 1){
      // debugger
      if(!name || !lastName || !email || !phone || email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null || phone.match("^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$") === null){
        if(!name){
          setNameError(true)
        }
        if(!lastName){
          setLastNameError(true)
        }
        if(!email){
          setEmailError(true)
        }
        if(email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null){
          setValEmailError(true)
        }
        if(!phone){
          setPhoneError(true)
        }
        if(phone.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$") === null){
          setValPhoneError(true)
        }        
      }
      else{
        // debugger
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step+1)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'valuation-step-two',
          'formId': 'valuation-step-two',
          'formName': 'Valuation Step Two',
          'formLabel': 'Valuation Step Two'
        });

        const json = {}
        json['formname'] = "Valuation Steps";
        json['name'] = sessionStorage.getItem('name');
        json['lastName'] = sessionStorage.getItem('lastName');
        json['email'] = sessionStorage.getItem('email');
        json['telephone'] = sessionStorage.getItem('telephone');
        
        const processFromData = async () => {
          let formData = new FormData();
          formData.append('data', JSON.stringify(json));
          JSON.stringify();  
          postFormData(formData).then(apiRes => { 
          });
        }
        processFromData();
      }
    }
    else if(step === 2){
      // debugger
      setAddressBuildingName($("input[name=addressBuildingName]").val());
      setAddressBuildingNumber($("input[name=addressBuildingNumber]").val());
      setAddressPostalCode($("input[name=addressPostalCode]").val());
      setAddressSubBuilding($("input[name=addressSubBuilding]").val());
      setAddressStreet($("input[name=addressStreet]").val());
      var valaddress   = $("input[name=address]").val();
      // if(valaddress == '') {
      //   $('.address-group').addClass('field-invalid');
      //   $('.steps-error').html("<div class='alert-error'><p>Please enter property address</p></div>");
       
      // } else {
      //   $('.address-group').removeClass('field-invalid');
      //   $('.steps-error').html('');
      // }
      if(!propertySell || !valaddress){
      // debugger
        stepSubmitted(true)
        if(!valaddress){
          $('.address-group').addClass('field-invalid');
          $('.error').html("<p>Please enter property address</p>");
        }
        else {
          $('.address-group').removeClass('field-invalid');
          $('.error').html('');
        }
      }
      else{
       // debugger
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        if(valuationType ==="Instant Valuation"){
          // debugger
          setStep(step+1)
        }
        else {        
          setStep(step+2)
        }
        
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'valuation-step-three',
          'formId': 'valuation-step-three',
          'formName': 'Valuation Step Three',
          'formLabel': 'Valuation Step Three'
        });
      }
    }
    else if(step === 3){
      // debugger
      setStep(step+1)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'valuation-step-three',
        'formId': 'valuation-step-three',
        'formName': 'Valuation Step Three',
        'formLabel': 'Valuation Step Three'
      });
      
    }
    else if(step === 4){
      // debugger
      var selectedaddress  = $("input[name=address]").val();
      $("input[name=selected_address]").val(selectedaddress);
      setDate(sessionStorage.getItem('date'))
      const formsdata = (event.target);
      event.preventDefault();
      const json = {}
      Object.keys(formsdata).map(key => (
          json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = "valuation_user";
      json['email_temp_admin'] = "valuation_admin";
      json['formname'] = "Valuation";
      if (json['subject']) {
          json['formname'] = 'Valuation';
      }
      json['type_of_valuation'] = sessionStorage.getItem('type_of_valuation');
      json['name'] = sessionStorage.getItem('name');
      json['lastName'] = sessionStorage.getItem('lastName');
      json['email'] = sessionStorage.getItem('email');
      json['telephone'] = sessionStorage.getItem('telephone');
      json['address'] = sessionStorage.getItem('addressLabel');
      json['addressPostalCode'] = sessionStorage.getItem('addressPostalCode');
      json['postcode'] = sessionStorage.getItem('postcode');
      json['sellorlet'] = sessionStorage.getItem('sellorlet');
      json['date_time'] = moment(sessionStorage.getItem('date')).format("YYYY-MM-DD HH:mm");
      json['g-recaptcha-response'] = token;
      json['to_email_id'] = process.env.VALUATION_MAIL_TO
      setFormvalues(json);

      recaptchaRef.current.execute();
         window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        setStep(step+1)
        window.scrollTo(0, 0);
     
    }
    // sessionStorage.setItem('step', step + 1)
  }
  const previous = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    // debugger
    if(step === 4){
      if(valuationType ==="Home Visit Valuation"){
        // debugger
        setStep(step-2)
      }
      else {
        // debugger
        setStep(step-1)     
      }
    }
    else {

      setStep(step-1)

    }

    // sessionStorage.setItem('step', step - 1)

  }
  useEffect(() => {
    // Initialize Bootstarp file upload field value retain js
    // bsCustomFileInput.init()
    // debugger
    if (token !== '') {

      const processFromData = async () => {

        let formData = new FormData();

        formData.append('data', JSON.stringify(formvalues));


        postFormData(formData).then(apiRes => {

            const axiosOptions_email = {
                url: '/.netlify/functions/server/valuation',
                method: "post",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                data: qs.stringify(formvalues),
            }

            axios(axiosOptions_email)
                .then(response => {
                    // console.log('mail sent!')
                    // next()
                    sessionStorage.removeItem("name")
                    sessionStorage.removeItem('lastName');
                    sessionStorage.removeItem("email")
                    sessionStorage.removeItem("telephone")
                    sessionStorage.removeItem("date")
                    sessionStorage.removeItem("address")
                    sessionStorage.removeItem("post_code")
                    sessionStorage.removeItem("type_of_valuation")
                    sessionStorage.removeItem('sellorlet');
                })
                .catch(err =>
                  {

                  }
                    // console.log(err)
                );
        });

          // tracking event
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'formSubmit',
            'formType': 'form-'+sessionStorage.getItem('type_of_valuation'),
            'formId': 'form-'+sessionStorage.getItem('type_of_valuation'),
            'formName': 'Valuation',
            'formLabel': 'Valuation' +propertySell
          });

          // next()

    }
    processFromData();


    }
}, [token]);
const handleonVerify = token => {
  // console.log("captcha verified");
  setToken(token);
};
// console.log(data1.glstrapi)
  return(

      <div style={{position:"relative"}}>
        <h1 style={{display:"none"}}>Property Valuation Manchester</h1>
        <SEO title={'Book a property Valuation'} description={'Know the accurate value of your property with free property valuation by Bentley Hurst. Book a valuation now to have a stress free property journey.'} url={'https://www.bentleyhurst.co.uk/property-valuation/'}/>
        <Helmet>
  <script type="application/ld+json">{`{
   "@context": "http://schema.org/",
  "@type": "Service",
  "serviceType": "Property Valuation",
  "brand": "https://www.bentleyhurst.co.uk/",
  "serviceOutput": "Property Valuation Manchester",
  "description": "How much is my property worth? Find out using an accurate property valuation service in Manchester with Bentley Hurst. Our no-obligation valuation awaits you.",
  "image": {
        "@type": "ImageObject",
        "caption": "Property Valuation Manchester",
        "contentUrl": "https://www.bentleyhurst.co.uk/images/mail-logo.png"
        },
  "provider": {
    "@type": "Organization",
    "name": "Bentley Hurst",
    "@id": "https://www.bentleyhurst.co.uk/images/mail-logo.png"
  },
  "areaServed": {
    "@type": "State",
    "name": "Manchester"
  }

   }`}</script>
 </Helmet>
         <Container>

     <Row>
         <Col lg="6">
           <Form>
           {step === 0 ? 
           <StepZero
           setValuationType={setValuationType}
           next={next}
           step={step}
           previous={previous}
           handleBack={handleBack}
           />
          : step === 1 ? 
         <StepOne 
         next={next}
         previous={previous}
         onChange={onChange}
         name={name}
         nameError={nameError}
         lastName={lastName}
         lastNameError={lastNameError}
         email={email}
         valemailError={valemailError}
         emailError={emailError}
         phone={phone}
         phoneError={phoneError}
         valphoneError={valphoneError}
         handleBack={handleBack}
         step={step}
         />

: step === 2 ?
<StepTwo 
addressBuildingName={addressBuildingName} addressBuildingNumber={addressBuildingNumber} addressPostalCode={addressPostalCode} addressSubBuilding={addressSubBuilding} addressStreet={addressStreet} valpalsell={valpalsell} valpallet={valpallet}
next={next}
previous={previous}
onChange={onChange}
submitted={submitted}
address={address}
addressError={addressError}
setAddress={setAddress}
setAddressError={setAddressError}
propertySell={propertySell}
handleBack={handleBack}
name={name}
email={email}
phone={phone}
step={step}
valuationType={valuationType}
 />

         :step === 3 ? 
         
      <StepThree 
      step={step}
      next={next}
         previous={previous}
         onChange={onChange}
         handleBack={handleBack}
name={name}
valuationType={valuationType}
      addressBuildingName={addressBuildingName} addressBuildingNumber={addressBuildingNumber} addressPostalCode={addressPostalCode} addressSubBuilding={addressSubBuilding} addressStreet={addressStreet} valpalsell={valpalsell} valpallet={valpallet}/>


         :
         step === 4 ? 
         <CalendarComponents 
         next={next}
         step={step}
         previous={previous}
         onChange={onChange}
         handleBack={handleBack}
         />
         :
         step === 5 ?
         <div className="steptwo">
         {/* <ScrollAnimation animateIn='fadeIn' animateOnce={true}> */}
             <a href="/" className="back_icon"><i className="icon-back"></i> Back to Home</a>
           <h2><span>All done! We look forward to seeing you on the {moment(date).format("Do MMM")} at {moment(date).format("hh:mma")}.</span></h2>
         {/* </ScrollAnimation> */}
          <div className="btn-val-blk">
         <Link className="btn btn-primary"
         to={FORSALE_Manchester_PAGE_URL.alias} 
        //  onClick={() => selectValuationType('Home Visit Valuation')}
         >Find a Property</Link>
         <Link className="btn btn-primary" 
         to={CONTACT_PAGE_URL.alias}
        //  onClick={() => selectValuationType('Home Visit Valuation')}
         >Contact Us</Link>
</div>
{/* {JSON.stringify(data1.glstrapi?.speak_with)} */}
<div className="contact-persion-blk pv-page">
<Link to={TEAM_PAGE_URL.alias+'/'+data1.glstrapi?.contact?.URL}>
                                    <div className="negotiator d-flex align-items-center">
                                        {/* {JSON.stringify(node)} */}
                                        <figure><img src={data1.glstrapi?.contact?.speak_with[0]?.Image?.url} alt="profile" /></figure>
                                        <div className="info">
                                            <p ><span className="name">Interested in Letting your property?</span> <br/>Speak with us on <span className="name"><a className="contact-no" href={"tel:" + data1.glstrapi?.contact?.Phone}>{data1.glstrapi?.contact?.Phone}</a></span></p>
                                            {/* <p className="post">{data1.glstrapi?.contact?.Designation}</p>
                                            <a className="contact-no" href={"tel:" + data1.glstrapi?.contact?.Phone}>{data1.glstrapi?.contact?.Phone}</a> */}
                                        </div>
                                    </div>
                                    </Link>
</div>
         </div>
         : null}
         </Form>
         <ReCaptchaBox
                className="py-2"
                captRef={recaptchaRef}
                key="captcha"
                handleonVerify={handleonVerify}
            />
           </Col>
           {/* <Col lg="6" className="pr-0 fixed-right d-none d-lg-block"> */}
             <div className="bg-image-book-view" style={{backgroundImage:`url(${BookViewImg})`}}>
               {/* <img src={data.images && data.images.length > 0 ? data.images[0].url: ""} alt="" className="book-view-img"/> */}
             </div>
           {/* </Col> */}
           
           </Row>
         
      </Container>
      </div>
         
  
)
  }



export default BookValuations








