import * as React from "react"
import { Link } from "@StarberryUtils"
import Header from '@Components/Header/Header';
import Footer from '@Components/Footer/Footer';
import PropertyValuation from "@Components/PropertyValuation/PropertyValuation"
import BookValuations from "../../Components/PropertyValuation/BookValuations";
const BookaValuation = ( props ) => {
  const [state,setState] = React.useState({
    showMenu:false
  })

  const handlerClick = () => {
    // console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }

  
  return(
    <div className={state.showMenu ? "wrapper bookview-page header-style open-search-block" : "wrapper bookview-page header-style"}>
    <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
    <BookValuations />
    <Footer/>
    </div>
         
  
)
  }



export default BookaValuation